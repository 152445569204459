import { Image } from 'src/components/ui/Image'
import { useEffect, useRef, useState } from 'react'
import { zoomIn, zoomOut, toggleZoom } from 'src/utils/zoomTools'
import { Modal } from 'src/components/common/Modal'
import Icon from 'src/components/ui/Icon'
import type { DktSliderFowardRef } from 'src/components/restructure/common/DktSlider/DktSlider'
import DktSlider from 'src/components/restructure/common/DktSlider/DktSlider'
import { useMobile } from 'src/hooks/useMobile'
import type { ProductItem } from 'src/components/product-page/context/types/product.type'
import { stripUrl } from 'src/utils/stripUrl'
import './styles.scss'

type Props = {
  currentSku: ProductItem
}

function formatSrc(url: string, width: number, height: number) {
  const hasId = url.includes('/arquivos/ids/')

  if (!hasId || !width || !height) {
    return url
  }

  const [id] = url.split('/arquivos/ids/')[1].split('/')[0].split('-')

  const formattedId = `${id}-${width}-${height}`

  return url.replace(id, formattedId)
}

export function GridImage({ currentSku }: Props) {
  const dktSliderRef = useRef<DktSliderFowardRef>(null)
  const thumbRef = useRef<DktSliderFowardRef>(null)
  const [curentSlide, setCurrentSlide] = useState(0)
  const zoomModalRef = useRef(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [zoomed, setZoomed] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState(0)
  const { images } = currentSku

  const { screenHeight, screenWidth, isMobile } = useMobile()

  const handleOpenModal = (indexSlide: number) => {
    setIsOpen(true)
    setSelectedImage(indexSlide)
  }

  useEffect(() => {
    dktSliderRef.current?.goToSlide(curentSlide)
    thumbRef.current?.goToSlide(curentSlide)
  }, [curentSlide])

  return (
    <>
      <div>
        <div className="flex h-fit md:h-[39rem] gap-x-6 gap-y-2 mt-4 md:mt-0 w-full flex-col-reverse md:flex-row">
          <div className="w-full md:w-[85px] h-full  flex items-center">
            <DktSlider
              direction={isMobile ? 'horizontal' : 'vertical'}
              slidesPerView={5}
              rewind={false}
              autoplay={false}
              className="kit-grid-image md:max-w-[83px]"
              ref={thumbRef}
              thumb
              sliderId="thumb"
            >
              {images?.map((image, idx) => (
                <button
                  key={image.imageUrl}
                  className="flex items-center justify-center"
                  onClick={() => dktSliderRef.current?.goToSlide(idx)}
                >
                  <Thumb key={idx} image={image} idx={idx} />
                </button>
              ))}
            </DktSlider>
          </div>

          <div className="w-full md:w-[calc(100%-85px)] h-full">
            <DktSlider
              showControls={false}
              centerItems={false}
              autoplay={false}
              ref={dktSliderRef}
              currentSlider={(idx) => setCurrentSlide(idx)}
              sliderId="kit-grid-image"
            >
              {images?.map((image, idx) => (
                <div key={image.imageUrl} className="!h-full">
                  <Image
                    className="h-full object-cover"
                    key={image.imageUrl}
                    src={image.imageUrl}
                    srcSet={formatSrc(image.imageUrl, 628, 628)}
                    width={628}
                    height={628}
                    alt={image.imageText}
                    loading={idx === 0 ? 'eager' : 'lazy'}
                    preload={idx === 0}
                    onMouseMove={(e) => zoomIn(e)}
                    onMouseLeave={(e) => zoomOut(e)}
                    onClick={() => handleOpenModal(idx)}
                  />
                </div>
              ))}
            </DktSlider>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        positionContent={screenWidth >= 1024 ? 'left-desktop' : 'left-mobile'}
        onClose={() => setIsOpen(false)}
      >
        <div className="p-8 w-[100vw] h-full flex flex-col gap-2">
          <button
            onClick={() => setIsOpen(false)}
            className="text-blue text-2xl ml-auto mr-0 flex"
          >
            <Icon name="Close" width={24} height={24} fill="#007cb9" />
          </button>
          <DktSlider
            ref={zoomModalRef}
            autoplay={false}
            startIndex={selectedImage}
            sliderId="zoom-modal"
          >
            {images?.map((image) => {
              return (
                <div key={image.imageUrl}>
                  <div className="overflow-x-scroll overflow-y-scroll">
                    <Image
                      className="mx-auto"
                      alt={image.imageText}
                      src={stripUrl(image.imageUrl)}
                      width={740}
                      height={screenHeight}
                      sizes="(max-width: 766px) 740px, 100vw"
                      onMouseMove={(e) => zoomIn(e)}
                      onMouseLeave={(e) => zoomOut(e)}
                      onClick={(e) => {
                        toggleZoom(e, zoomed)
                        setZoomed(!zoomed)
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </DktSlider>
        </div>
      </Modal>
    </>
  )
}

type ThumbProps = {
  image: {
    imageUrl: string
    imageText: string
  }
  idx: number
}

function Thumb({ image, idx }: ThumbProps) {
  return (
    <Image
      key={image.imageText}
      srcSet={formatSrc(image.imageUrl, 128, 128)}
      src={image.imageUrl}
      width={128}
      height={128}
      alt={image.imageText}
      style={{ objectFit: 'contain' }}
      loading={idx <= 4 ? 'eager' : 'lazy'}
      preload={idx <= 4}
      className="h-[56px] w-[56px] md:h-[85px] md:w-[85px]"
    />
  )
}
